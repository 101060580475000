@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.chartDesc {
  margin-top: 20px;
  padding: 0 10px;
  @include typo.font-15-400;
  color: color.$black-300;
}

.gradeSelectDesc {
  margin-top: 10px;
  display: flex;
  align-items: center;
  @include typo.font-14-400;
  color: color.$black-300;
}

.selectedColorBullet {
  margin-left: auto;
  margin-right: 5px;
  height: 20px;
  width: 20px;
  border: 1px solid color.$black-300;
  background-color: color.$purple-150;
}

.chartWrapper {
  padding-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  row-gap: 30px;
}

.solutionDescription {
  margin-top: 20px;
  padding: 0 40px;
  @include typo.font-15-400;
  color: color.$black-300;
}

.dataTableWrapper {
  width: 505px;
}