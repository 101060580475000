@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: color.$default-font-color;
}

.panelGridTemplate {
  grid-template-columns: 30px 3fr 2fr 1fr 1fr;
}

.summaryGridTemplate {
  grid-template-columns: 3fr 2fr 1fr 1fr;
}

.head {
  @include typo.font-12-700;
  display: grid;
  border-bottom: 1px solid color.$gray-800;
}

.headCell {
  padding: 7px 12px;
}

.body {
  @include typo.font-12-400;
  flex: 1 1 0;
  overflow-y: auto;
  overflow-y: overlay;
  border-bottom: 1px solid color.$gray-800;
  padding: 10px 0;
}
