@use '/src/style/css/layout.scss';

.root {
  @include layout.app-layout;
  overflow: auto;
}

.toastContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: pre-wrap;
}
