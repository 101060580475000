@use '/src/style/css/typo.scss';
@use '/src/style/css/layout.scss';
@use '/src/style/css/variable/color.scss';

.root {
  @include layout.page;
  padding-top: 40px;
}

.headerText {
  @include typo.font-42-700;
  color: color.$black-100;
  margin-right: auto;
}

.body {
  width: 1064px;
  height: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.control {
  @include typo.font-12-700;
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 210px 30px;
  text-align: end;
  align-items: center;
  gap: 15px;
}

.grid {
  flex: 1;
}