@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  width: 800px;
  margin: 0 auto;
}

.title {
  @include typo.font-28-700;
  color: color.$default-font-color;
  margin-top: 50px;
}

.goalRequired {
  @include typo.font-20-700;
  color: color.$default-font-color;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 60px;
}

.item {
  display: inline-flex;
  gap: 10px;
  align-items: center;
  white-space: nowrap;
}

.required {
  margin-left: 5px;
  color: color.$primary-color;
}

.optionalTitle {
  @include typo.font-17-700;
  color: color.$default-font-color;
  margin-bottom: 20px;
}

.goalOptional {
  @include typo.font-17-400;
  color: color.$default-font-color;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.disabledOptional {
  opacity: 0.5;
}

.optionalInputWrapper {
  width: 160px;
}

.footer {
  margin-top: 100px;
  display: grid;
  align-items: center;
  gap: 25px;
  .outQnaLink {
    @include typo.font-12-500;
    margin-right: auto;
    color: color.$gray-300;
    text-decoration: underline;
  }
  grid-template-columns: auto 150px 150px;
}
