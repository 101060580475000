@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  height: 30px;
}

.item {
  position: relative;
  min-height: 30px;
  border-radius: 15px;
  padding: 7px 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: color.$blue-050;
  color: white;
  gap: 10px;
  &:hover {
    cursor: pointer;
  }
}

.deleteIcon {
  width: 10px;
  height: 10px;
  color: white;
}

.fieldName {
  @include typo.font-12-700;
}

.values {
  @include typo.font-12-400;
}

.addIcon {
  width: 10px;
}
