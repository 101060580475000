@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  padding: 10px 15px 15px;
  overflow-y: auto;
}

.conditionItem {
  display: block;
  &:not(:first-child) {
    margin-top: 25px;
  }
}

.conditionName {
  margin-left: 15px;
  @include typo.font-14-400;
  color: color.$default-font-color;
}