$app-min-width: 1600px;
$app-min-height: 900px;

$left-nav-bar-width: 124px;
$left-nav-bar-height: 100%;

$right-page-width: calc(100% - $left-nav-bar-width);
$max-right-page-width: 1700px;
$right-page-height: 100%;

$page-content-width: 1336px;

$login-height: 100%;
$login-width: 50%;
