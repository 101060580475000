@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  width: 1065px;
  margin: 0 auto;
}

.sectionTitle {
  @include typo.font-28-700;
  color: color.$black-100;
  margin-top: 50px;
}

.sectionDesc {
  @include typo.font-12-400;
  display: block;
  color: color.$gray-500;
  margin-top: 10px;
}

.uploadSection {
  @include typo.font-18-500;
  min-height: 320px;
  margin-top: 40px;
  margin-bottom: 0px;
  list-style-position: inside;
  padding-left: 0;
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  >:first-child {
    grid-column: 1;
    grid-row: 1 / span 2;
  }
  >:nth-child(n):not(:first-child):not(:last-child) {
    grid-column: 2;
    grid-row: span 1;
    &:nth-child(2):nth-last-child(2) {
      grid-row: 1 / span 2;
    }
  }
  >:last-child {
    grid-column: 3;
    grid-row: 1 / span 2;
  }
}

.uploadTitle {
  height: 35px;
}

.uploadLabel {
  display: inline-flex;
  margin-bottom: 10px;
  gap: 10px;
}

.uploadBody {
  height: calc(100% - 35px);
  overflow: hidden;
}

.datasetInfo {
  max-width: 240px;
}

.uploadDesc {
  @include typo.font-10-400;
  display: block;
  color: color.$gray-500;
  margin-top: 15px;
}

.footer {
  margin-top: 80px;
  display: grid;
  gap: 25px;
  align-items: center;
  .outQnaLink {
    @include typo.font-12-500;
    margin-right: auto;
    color: color.$gray-300;
    text-decoration: underline;
  }
  grid-template-columns: auto 150px 150px;
}
