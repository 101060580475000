@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  width: 100%;
  margin: 50px auto 0;
  color: color.$default-font-color;
}

.body {
  display: grid;
  grid-template:
    "summary  segments" 0fr
    "list     segments" 1fr
    / 420px 1fr;
  column-gap: 70px;
  row-gap: 40px;
  height: 600px;
}

.summary {
  grid-area: summary;
}

.list {
  grid-area: list;
  display: flex;
  flex-direction: column;
}

.segments {
  grid-area: segments;
  display: flex;
  flex-direction: column;
}

.header {
  @include typo.font-20-700;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.flexColumn {
  flex-direction: column;
  align-items: baseline;
}

.flexEnd2End {
  justify-content: space-between;
}

.description {
  @include typo.font-16-400;
  color: color.$gray-200;
}

.downloadButton {
  text-decoration: underline;
  display: flex;
  align-items: center;
  gap: 10px;
  @include typo.font-12-700;
  color: color.$default-font-color;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  .downloadIconWrapper {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: color.$default-black-color;
    .downloadIcon {
      width: 16px;
      height: 16px;
      color: white;
    }
  }
}

.summaryBody {
  border: 1px solid color.$gray-680;
  border-radius: 8px;
  padding: 15px 25px;
  display: grid;
  grid-template: auto / 2fr 1fr;
  gap: 10px;
  .summaryLabel {
    @include typo.font-14-400;
  }
  .summaryValue {
    text-align: right;
    @include typo.font-14-700;
  }
}

.listGrid {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1 1 0;
}

.gridHeadRow {
  @include typo.font-16-600;
  color: white;
  height: 35px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  &.with3Columns {
    grid-template-columns: 1fr 1fr 1fr;
  }
  background-color: color.$black-100;
}

.cell {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  overflow: hidden;
  &:not(:first-child) {
    border-left: 1px solid color.$gray-650;
  }
}

.textEllipsed {
  text-overflow: ellipsis;
  overflow: hidden;
}

.headCell {
  @extend .cell;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gridBody {
  flex: 1;
  box-shadow: inset 0 0 0px 1px color.$gray-650;
  border-top: 0px solid color.$gray-650;
  overflow-y: auto; // overlay fallback
  overflow-y: overlay;
}

.gridBodyRow {
  @include typo.font-14-400;
  height: 35px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  &.with3Columns {
    grid-template-columns: 1fr 1fr 1fr;
  }
  &:nth-child(n) {
    border-bottom: 1px solid color.$gray-650;
  }
}

.rowCell {
  @extend .cell;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  margin-top: 25px;
  display: grid;
  align-items: center;
  .outQnaLink {
    @include typo.font-12-500;
    margin-right: auto;
    color: color.$gray-300;
    text-decoration: underline;
  }
  grid-template-columns: 1fr auto;
}
