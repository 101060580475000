@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  box-sizing: border-box;
  padding: 0 5px;
  height: 16px;
  border: 1px solid color.$gray-300;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @include typo.font-10-500;
  color: color.$gray-300;
}