@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  border: 1px solid color.$gray-800;
  color: color.$default-font-color;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: color.$gray-500
  }
  &:disabled {
    opacity: 0.6;
    &.isDarkDisabled {
      background-color: color.$gray-680;
    }
    color: white;
  }
  &[type='number'] {
    text-align: right;
  }
  &.isError {
    border: 1px solid color.$red-orange;
  }
}

.xsSize {
  @include typo.font-12-400;
  border-radius: 6px;
  padding: 0 8px;
  height: 30px;
  &.smWidth {
    width: 37.5px;
  }
  &.mdWidth {
    width: 52.5px;
  }
  &.lgWidth {
    width: 82.5px;
  }
}

.smSize {
  @include typo.font-14-500;
  border-radius: 6px;
  padding: 0 10px;
  height: 34px;
  &.smWidth {
    width: 42.5px;
  }
  &.mdWidth {
    width: 59.5px;
  }
  &.lgWidth {
    width: 93.5px;
  }
}

.mdSize {
  @include typo.font-14-500;
  border-radius: 6px;
  padding: 0 12px;
  height: 40px;
  &.smWidth {
    width: 50px;
  }
  &.mdWidth {
    width: 70px;
  }
  &.lgWidth {
    width: 110px;
  }
}

.lgSize {
  @include typo.font-14-500;
  border-radius: 6px;
  padding: 0 12px;
  height: 44px;
  &.smWidth {
    width: 55px;
  }
  &.mdWidth {
    width: 81px;
  }
  &.lgWidth {
    width: 121px;
  }
}

.borderBottom {
  border-radius: 0px;
  border: 0px solid color.$gray-800;
  border-bottom: 1px solid color.$gray-800;
  &.isError {
    border-bottom: 1px solid color.$red-orange;
  }
}

.borderNone {
  border: 0px solid color.$gray-800;
}

.fullWidth {
  width: 100%;
}