.error {
  margin-left: 10px;
  width: 800px;
  .errCode {
    font-weight: bold;
    margin-bottom: 20px;
  }
  .message {
    margin-bottom: 20px;
  }
}