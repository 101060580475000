@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  border: 1px solid color.$gray-800;
  background-color: white;
  border-radius: 4px;
  box-shadow: 1px 2px 4px color.$gray-100;
  height: 250px;
  display: flex;
  flex-direction: column;
}

.title {
  @include typo.font-12-500;
  padding: 20px;
  height: 54px;
  display: flex;
  align-items: center;

}

.inputArea {
  padding: 0 20px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.sliderArea {
  margin-top: 20px;
  padding: 0 25px;
}

.footer {
  margin-top: auto;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  box-shadow: inset 0px 1px color.$gray-900;
}