@use '/src/style/css/variable/color.scss';

.root {
  background-color: white;
  overflow: hidden;
  width: 100%;
}

.smSize {
  height: 4px;
}

.mdSize {
  height: 12px;
}

.rounded {
  border-radius: 20px;
}

.outlined {
  border: 1px solid color.$default-font-color;
}

.progress {
  background-color: color.$default-font-color;
  height: 100%;
  transition: cubic-bezier(0, 0, 0.2, 1) 0.5s width,
}

.primary {
  background-color: color.$primary-color;
}

.secondary {
  background-color: color.$secondary-color;
}
