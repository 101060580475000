@use '/src/style/css/variable/color.scss';

.root {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
}

.children {
  display: flex;
  align-items: center;
  width: 100%;
}

.icon {
  color: color.$black-100;
}

.infoArea {
  display: flex;
  align-items: center;
  margin-left: 50px;
}

