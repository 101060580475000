@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  width: 650px;
  padding: 0 40px 25px;
}

.body {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include typo.font-20-500;
  color: color.$default-font-color;
}

.footer {
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 15px;
  grid-template-columns: 180px 180px;
}
