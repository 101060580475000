@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.fieldset {
  display: flex;
  align-items: center;
  gap: 10px;
}

.fieldSection {
  width: 215px;
}
