@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.profile {
  margin-left: 30px;
  border-radius: 22px;
  width: 44px;
  height: 44px;
  overflow: hidden;
  background-color: color.$gray-200;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: white;
  @include typo.font-18-500;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}

.anchor {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: end;
}

.content {
  margin-top: 10px;
  width: 300px;
  height: 160px;
  position: absolute;
  border: 1px solid color.$gray-800;
  border-radius: 6px;
  background-color: white;
  color: color.$default-black-color;
  @include typo.font-12-400;
  z-index: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 3px 0px color.$gray-800;
}

.info {
  padding: 20px 20px 25px;
  display: grid;
  grid-template-areas:
    "profile nickName"
    "profile email"
    "profile setting";
  grid-template-columns: 90px 1fr;
  grid-template-rows: 22px 22px auto;
}

.infoProfile {
  grid-area: profile;
  border-radius: 40px;
  width: 60px;
  height: 60px;
  overflow: hidden;
  background-color: color.$gray-200;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: white;
  @include typo.font-24-500;
  img {
    width: 100%;
    height: 100%;
  }
}

.nickName {
  grid-area: nickName;
  @include typo.font-16-700;
  color: color.$default-black-color;
}

.email {
  grid-area: email;
  @include typo.font-12-400;
  color: color.$default-black-color;
}

.setting {
  grid-area: setting;
  @include typo.font-14-500;
  color: color.$default-black-color;
  display: flex;
  align-items: flex-end;
}

.settingNav {
  color: color.$default-black-color;
}

.signOut {
  border-top: 1px solid color.$gray-800;
  @include typo.font-14-400;
  display: flex;
  align-items: center;
  flex: 1 1 0;
  padding: 0 20px;
  cursor: pointer;
  &:hover {
    background-color: color.$blue-300;
  }
}
