@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  max-width: 320px;
  height: 160px;
  border-radius: 16px;
  background-color: color.$gray-700;
  color: color.$black-100;
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  button {
    margin-left: auto;
  }
}

.orangeColorSet {
  background-color: color.$orange-200;
  color: color.$orange-100;
}

.purpleColorSet {
  background-color: color.$purple-200;
  color: color.$purple-100;
}

.blueColorSet {
  background-color: color.$blue-200;
  color: color.$blue-100;
}

.pinkColorSet {
  background-color: color.$pink-200;
  color: color.$pink-100;
}

.mintColorSet {
  background-color: color.$mint-200;
  color: color.$mint-100;
}

.blackColorSet {
  background-color: color.$white-500;
  color: color.$black-200;
}

.title {
  @include typo.font-20-700;
  margin-bottom: 8px;
}

.description {
  @include typo.font-12-400;
  margin-bottom: auto;
}

.innerButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  .icon {
    width: 10px;
    height: 10px;
  }
}
