@use '/src/style/css/typo.scss';
@use '/src/style/css/layout.scss';
@use '/src/style/css/variable/color.scss';

.root {
  @include layout.page;
  padding-top: 40px;
}

.headerText {
  margin-left: 30px;
  color: color.$default-font-color;
  @include typo.font-42-700;
  margin: 0 0 5px 0;
}

.bodyHeader {
  @include typo.font-28-700;
  color: color.$default-font-color;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.buttonTabsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  overflow: hidden;
}

.buttonTab {
  height: 40px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include typo.font-16-500;
  background-color: color.$gray-900;
  color: color.$gray-400;
  cursor: pointer;
  &.active {
    background-color: color.$black-200;
    color: color.$gray-900;
  }
}
