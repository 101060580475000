@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  width: 100%;
  color: color.$black-200;
}

.tabContentHeader {
  margin-top: 40px;
  display: flex;
  align-items: center;
  @include typo.font-20-600;
}

.branchController {
  @include typo.font-14-500;
  margin-left: auto;
  display: grid;
  align-items: center;
  gap: 10px;
  grid-template-columns: 50px 180px;
}

.lookbackPeriodController {
  @include typo.font-14-500;
  margin-left: 50px;
  display: grid;
  align-items: center;
  gap: 10px;
  grid-template-columns: 70px 180px;
}

.isUnspecifiedIndustry {
  margin-top: 10px;
  padding-left: 7px;
  @include typo.font-14-600;
  color: color.$primary-color;
  line-height: 1.6;
}

.chartWrapper {
  padding-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.tableWrapper {
  margin-top: 60px;
  border-radius: 4px;
  border: 1px solid color.$gray-900;
  margin-bottom: 60px;
}

.industryCell {
  display: flex;
  align-items: center;
  align-self: normal;
  cursor: pointer;
}

.bullet {
  height: 12px;
  width: 12px;
  border-radius: 2px;
  margin-right: 10px;
}

.notSelected {
  opacity: 0.2;
}