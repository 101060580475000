@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  display: inline-block;
  cursor: pointer;
  &:hover > .anchor > .info {
    display: block;
  }
}

.icon {
  width: 20px;
  height: 20px;
}

.anchor {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info {
  display: none;
  position: absolute;
  background-color: color.$default-black-color;
  border-radius: 3px;
  color: white;
  padding: 8px 12px;
  @include typo.font-12-400;
  z-index: 1;
}