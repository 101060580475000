@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  position: relative;
  padding: 15px;
  width: 100%;
  height: 100%;
  background-color: color.$white-500;
  border: 1px dashed color.$gray-800;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  &.isFile {
    display: block;
  }
  &.horizonalLayout {
    flex-direction: row;
  }
}

$csv-icon-width: 33px;
$check-icon-width: 24px;
$trash-icon-width: 20px;
$uploaded-file-gap: 15px;

.fileWrapper {
  background: white;
  border: 1px solid color.$purple-150;
  border-radius: 4px;
  height: 80px;
  display: flex;
  flex-direction: column;
}

.uploadedFile {
  width: 100%;
  flex: 1 1 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0 20px;
  gap: $uploaded-file-gap;
}

.fileInfo {
  margin-right: auto;
  flex: 1 1 0;
  overflow: hidden;
}

.fileName {
  @include typo.font-14-500;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  color: color.$default-font-color;
  margin-bottom: 5px;
}

.fileSize {
  @include typo.font-12-400;
  color: color.$gray-400;
}

.cancelIcon {
  width: 24px;
  height: 24px;
  color: color.$gray-660;
  margin-right: 35px;
}

.progressIcon {
  width: 24px;
  height: 24px;
  color: color.$gray-660;
}

.checkedIcon {
  width: 24px;
  height: 24px;
  color: color.$secondary-color;
}

.trashIcon {
  color: color.$gray-800;
  cursor: pointer;
}

.fileSelectButton {
  @include typo.font-14-500;
  height: 36px;
  border-radius: 6px;
  padding: 0 17px;
  background-color: color.$secondary-color;
  border: 1px solid color.$secondary-color;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  input {
    display: none;
  }
  &:hover {
    opacity: 0.7;
  }
}

.onDrag {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 12px;
  border: 2px dashed color.$gray-100;
  background-color: color.$white-500;
  opacity: 0.5;
}

.visible {
  display: block;
}