.rc-slider {
  margin: 0 auto;
  width: 75%
  fontSize 12;
}

.rc-slider-handle {
  background-color: #7C8EDB;
  border: 2px solid #7C8EDB;
  opacity: 1;
}

.rc-slider-handle.rc-slider-handle:hover {
  border: 2px solid #7C8EDB;
}

.rc-slider-disabled .rc-slider-handle {
  background-color: #7C8EDB;
  border: 2px solid #7C8EDB;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #7C8EDB;
  box-shadow: 0 0 0 5px #7C8EDB;
}

.rc-slider-track.rc-slider-track-1 {
  background-color: #7C8EDB;
}

.rc-slider-disabled.rc-slider-track {
  background-color: #7C8EDB;
}

.rc-slider-dot {
  background-color: #7C8EDB;
  border: 2px solid #7C8EDB;
}

.rc-slider-dot.rc-slider-dot-active {
  border: 2px solid #7C8EDB;
}

.rc-slider-mark {
  margin-top: 5px;
}

.rc-slider-mark-text {
  color: #232020;
  pointer-events: 'none';
}

.rc-slider-mark-text-active {
  display: inline-block;
  color: #7C8EDB;
}