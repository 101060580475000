@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  margin: 0 auto;
  display: grid;
  grid-template-rows: 650px 1fr;
}

.body {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.training {
  @extend .body;
  gap: 20px;
}

.progressBarWrapper {
  width: 500px;  
}

.result {
  @extend .body;
  gap: 40px;
}

.notice {
  @include typo.font-28-700;
}

.resultSummary {
  @include typo.font-16-400;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.buttonWrapper {
  margin-top: 20px;
  width: 310px;
  display: grid;
}

.csiBtnWrapper {
  width: auto;
  grid-template-columns: 310px 310px;
  // TEMPORARY
  // grid-template-columns: auto auto auto;
  gap: 10px;
}

.logo {
  width: 280px;
  height: 280px;
  margin-bottom: -40px;
}

.lottie {
  width: 320px;
  height: 320px;
}

.footer {
  display: grid;
  align-items: center;
  justify-content: center;
  .outQnaLink {
    @include typo.font-12-500;
    margin-right: auto;
    color: color.$gray-300;
    text-decoration: underline;
  }
}
