$red-orange: #FF5348;
$sky-purple: #7C8EDB;

$orange-100: #E25E49;
$orange-150: #F49085;
$orange-200: #FFF0EB;

$pink-100: #C640AF;
$pink-150: #FD75D4;
$pink-200: #FFE9FB;

$mint-100: #04958A;
$mint-200: #E1FFFA;

$purple-100: #5950B7;
$purple-120: #7C90DB;
$purple-130: #9EAEF1;
$purple-140: #A391FF;
$purple-150: #C7D1EA;
$purple-160: #D1D1F8;
$purple-170: #D2DAEF;
$purple-190: #EBEAF9;
$purple-200: #EEEEFD;

$skyblue-500: #6BAAF7;

$blue-050: #3D54B2;
$blue-100: #108AE2;
$blue-200: #E8F4F9;
$blue-250: #EDF1FA;
$blue-300: #EFF3FD;
$blue-400: #F2F4F6;

$green-400: #54DF8B;

$black-100: #232020;
$black-200: #333333;
$black-300: #434343;

$gray-100: #5B5B5B;
$gray-200: #666666;
$gray-300: #696969;
$gray-400: #878787;
$gray-450: #999999;
$gray-500: #ADADAD;
$gray-600: #B4B5B7;
$gray-650: #C5C5C5;
$gray-660: #CCCCCC;
$gray-680: #D6D6D6;
$gray-700: #D9D9D9;
$gray-800: #DDDDDD;
$gray-900: #EEEEEE;

$white-500: #F9F9F9;

$primary-color: $red-orange;
$secondary-color: $sky-purple;
$tertiary-color: $purple-200;

$default-black-color: $black-100;
$default-font-color: $black-100;
$default-button-color: $black-100;
