@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  min-width: 200px;
  border: 1px solid color.$gray-800;
  background-color: white;
  border-radius: 4px;
  box-shadow: 1px 2px 4px color.$gray-100;
  display: flex;
  flex-direction: column;
}

.title {
  @include typo.font-12-500;
  padding: 20px;
  height: 54px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.catogoryArea {
  overflow-y: auto;
  flex: 1 1 0;
}

.categoryItem {
  padding: 0 20px;
  height: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.categoryName {
  margin-left: 15px;
  @include typo.font-12-400;
  color: color.$default-font-color;
}

.footer {
  margin-top: auto;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  box-shadow: inset 0px 1px color.$gray-900;
}
