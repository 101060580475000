@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

$first-col-width: 245px;

.cell {
  width: 100%;
  height: 100%;
  padding: 0 20px;
}

.required {
  margin-left: 5px;
  color: color.$primary-color;
}

.gridBodyRow {
  height: 70px;
  display: grid;
  align-items: center;
  grid-template-columns: $first-col-width calc((100% - $first-col-width) / 2) calc((100% - $first-col-width) / 2);
}

.blueprintCell {
  @extend .cell;
  display: flex;
  align-items: center;
  background-color: color.$blue-250;
}

.inputCell {
  @extend .cell;
  padding: 0 30px 0 50px;
  display: grid;
  align-items: center;
  grid-template-columns: auto;
}

.descCell {
  @extend .cell;
  @include typo.font-12-400;
  color: color.$default-font-color;
  white-space: pre;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
