@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.panelGridTemplate {
  grid-template-columns: 30px 3fr 2fr 1fr 1fr;
}

.summaryGridTemplate {
  grid-template-columns: 3fr 2fr 1fr 1fr;
}

.bodyRow {
  display: grid;
  height: 35px;
  align-items: center;
  color: color.$default-font-color;
  &:hover {
    background-color: color.$purple-200;
    cursor: pointer;
    &.notAvailable {
      background-color: color.$white-500;
    }
  }
  &:hover .editNameButton {
    display: flex;
  }
  &.notAvailable {
    color: rgba(color.$default-font-color, 0.5);
    cursor: not-allowed;
  }
}

.checkCell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bodyCell {
  padding: 0 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.projectName {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}

.editNameButton {
  display: none;
  align-items: center;
  justify-content: center;
  color: color.$purple-100;
  width: 30px;
  height: 30px;
  border-radius: 3px;
  &:hover {
    background-color: color.$purple-160;
    cursor: pointer;
  }
}

.projectInfo {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 30px;
}

.primaryColor {
  color: color.$primary-color;
  &.notAvailable {
    color: rgba(color.$primary-color, 0.5);
  }
}

.orangeColor {
  color: color.$orange-100;
  &.notAvailable {
    color: rgba(color.$orange-100, 0.5);
  }
}

.blueColor {
  color: color.$blue-100;
  &.notAvailable {
    color: rgba(color.$blue-100, 0.5);
  }
}

.mintColor {
  color: color.$mint-100;
  &.notAvailable {
    color: rgba(color.$mint-100, 0.5);
  }
}

.purpleColor {
  color: color.$purple-100;
  &.notAvailable {
    color: rgba(color.$purple-100, 0.5);
  }
}

.pinkColor {
  color: color.$pink-100;
  &.notAvailable {
    color: rgba(color.$pink-100, 0.5);
  }
}
