@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  width: 100%;
  margin: 50px auto 0;
  display: flex;
  flex-direction: column;
}

.titleArea {
  @include typo.font-16-600;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.content {
  height: 0px;
  transition: .5s height;
  overflow-y: hidden;
}

.expantion {
  height: inherit;
}

.dropdownIcon {
  color: color.$gray-200;
  &.disabled {
    color: #949494;
  }
  &.reversed {
    transform: rotatex(180deg),
  }
}
