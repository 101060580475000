@use '/src/style/css/typo.scss';
@use '/src/style/css/layout.scss';
@use '/src/style/css/variable/color.scss';

.root {
  height: 48px;
  display: grid;
  grid-template-columns: 75px 150px 1fr 100px;
  align-items: center;
  @include typo.font-14-500;
  &:hover {
    background-color: color.$purple-200;
  }
  &:hover .editNameButton {
    display: flex;
  }
  &.active {
    background-color: color.$purple-160;
  }
}

.checkboxCell {
  justify-self: center;
}

.editNameButton {
  justify-self: center;
  display: none;
  align-items: center;
  justify-content: center;
  color: color.$purple-100;
  width: 30px;
  height: 30px;
  border-radius: 3px;
  &:hover {
    background-color: color.$purple-160;
    cursor: pointer;
  }
}