@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  width: 100%;
  color: color.$black-200;
}

.tabContentHeader {
  margin-top: 40px;
  display: flex;
  align-items: center;
  @include typo.font-16-600;
}

.tabContentHeaderIndustryController {
  @include typo.font-14-500;
  margin-left: auto;
  display: grid;
  align-items: center;
  gap: 10px;
  grid-template-columns: 50px 180px 180px 180px;
}

.tabContentHeaderCorpTypeController {
  @include typo.font-14-500;
  margin-left: 50px;
  display: grid;
  align-items: center;
  gap: 10px;
  grid-template-columns: 50px 180px;
}

.isUnspecifiedIndustry {
  margin-top: 10px;
  padding-left: 7px;
  @include typo.font-14-600;
  color: color.$primary-color;
  line-height: 1.6;
}

.firstChartTitle {
  margin-top: 20px;
  @include typo.font-24-600;
}

.chartTitle {
  margin-top: 50px;
  @include typo.font-24-600;
}

.chartWrapper {
  padding-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
  row-gap: 50px;
}
