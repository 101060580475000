@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  width: 100%;
  margin: 0 auto;
}

.sectionTitle {
  @include typo.font-28-700;
  color: color.$black-100;
  margin-top: 50px;
}

.mappingSection {
  @include typo.font-14-500;
  margin-top: 20px;
}

.mappingState {
  @include typo.font-14-500;
  display: block;
  text-align: end;
  margin-bottom: 10px;
}

.mappingGrid {
  width: 100%;
  height: 520px;
  display: flex;
  flex-direction: column;
  border: 1px solid color.$gray-650;
}

$first-col-width: 245px;

.gridHeadRow {
  @include typo.font-16-600;
  background-color: color.$black-100;
  color: white;
  height: 50px;
  display: grid;
  align-items: center;
  grid-template-columns: $first-col-width calc((100% - $first-col-width) / 2) calc((100% - $first-col-width) / 2);
}

.cell {
  width: 100%;
  height: 100%;
  padding: 0 20px;
}

.headCell {
  @extend .cell;
  display: flex;
  align-items: center;
}

.inputHeadCell {
  @extend .headCell;
  padding: 0 30px 0 60px;
}

.gridBody {
  flex: 1;
  overflow-y: auto;
}

.footer {
  margin-top: 24px;
  display: grid;
  gap: 25px;
  align-items: center;
  .outQnaLink {
    @include typo.font-12-500;
    margin-right: auto;
    color: color.$gray-300;
    text-decoration: underline;
  }
  grid-template-columns: auto 150px 150px;
}
