@use '/src/style/css/variable/color.scss';
@use '/src/style/css/layout.scss';
@use '/src/style/css/typo.scss';

.root {
  @include layout.lnb-layout;
  background-color: color.$black-100;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-top: 50px;
  padding-bottom: 30px;
}

.logo {
  color: white;
  margin-bottom: 110px;
}

.navigation {
  display: grid;
  row-gap: 40px;
}

.storageArea {
  margin-top: auto;
  width: 75px;
  @include typo.font-10-500;
  text-align: center;
  color: color.$gray-400;
  .storageInfo {
    margin-bottom: 10px;
  }
}
