@use '/src/style/css/typo.scss';

html,
body,
div[id=root] {
  height: 100%;
}

html {
  @include typo.font-family-roboto;
}

div,
ul,
ol,
li,
a,
nav,
header,
section,
input,
::before {
  box-sizing: border-box;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
