@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  display: flex;
  width: 575px;
  height: 35px;
  margin: 0 20px;
}

.bar {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 24px;
  background-color: color.$gray-900;
  &:first-child {
    width: 0px;
  }

  .item {
    margin-left: -12px;
    margin-right: -12px;
    width: 24px;
    height: 55px;
    overflow: visible;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    label {
      @include typo.font-10-400;
      margin-top: 10px;
      white-space: nowrap;
      word-break: keep-all;
      color: color.$gray-500;
    }

    .icon {
      color: color.$gray-700;
      width: 24px;
      height: 24px;
    }
  }

  &.processed {
    background-color: color.$gray-600;
    .icon {
      color: color.$black-100;
    }
  }

  &.active {
    label {
      color: color.$default-font-color;
    }
  }

  &.active {
    label {
      color: color.$default-font-color;
    }
  }

}
