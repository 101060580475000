@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.controlLabelArea {
  margin-top: 20px;
  display: grid;
  grid-template-columns: auto 170px 170px 170px 170px 70px;
  align-items: center;
  justify-content: end;
  gap: 10px;
  @include typo.font-12-400;
}

.controlArea {
  margin-top: 5px;
  display: grid;
  grid-template-columns: auto 170px 170px 170px 170px 70px;
  align-items: center;
  justify-content: end;
  gap: 10px;
  height: 40px;
}

.sectionTitle {
  margin-top: 20px;
  @include typo.font-16-600;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.analysisTargetArea {
  @include typo.font-14-500;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;  
  align-items: center;
}

.chartArea {
  margin-top: 20px;
  height: 450px;
  overflow: hidden;
  display: flex;
  gap: 10px;
}

.chartWrapper {
  flex: 1 0 0;
  margin-top: 50px;
}

.listArea {
  width: 400px;
  display: flex;
  flex-direction: column;
}

.listHeader {
  height: 20px;
  @include typo.font-14-600;
  text-align: right;
  color: color.$default-font-color;
  margin-bottom: 10px;
  padding-right: 10px;
}

.listWrapper {
  flex: 1 0 0;
  overflow-y: auto;
  margin: 0;
  padding: 0;
}

.listItem {
  @include typo.font-12-400;
  color: color.$default-font-color;
  width: 100%;
  border-radius: 5px;
  box-shadow: inset 0 0 0 1px color.$gray-500;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  gap: 5px;
  margin-bottom: 2px;
  cursor: pointer;
}

.activeItem {
  background-color: color.$secondary-color;
  color: color.$white-500;

}

.bullet {
  height: 13px;
  width: 13px;
  border-radius: 2px;
  margin-right: 10px;
  background-color: color.$default-black-color;
}

.companyName {
  width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dataTableArea {
  margin-top: 30px;
  display: flex;
  gap: 30px;
}

.tableTitle {
  @include typo.font-16-600;
  margin-bottom: 20px;
}

.newTable {
  width: 50%;
}

.asisTable {
  width: 50%;
}

.tableWrapper {
  border-top: 2px solid color.$gray-400;
  border-bottom: 2px solid color.$gray-400;
}

.dataTableTotalRow {
  height: 60px;
  display: grid;
  grid-template-columns: 35px 5fr 5fr 5fr 120px;
  @include typo.font-12-500;
  >.leftCell {
    padding: 0 10px;
    border-top: 1px solid color.$gray-400;
    display: flex;
    align-items: center;
  }
  >.centerCell {
    padding: 0 10px;
    border-top: 1px solid color.$gray-400;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  >.rightCell {
    padding: 0 10px;
    border-top: 1px solid color.$gray-400;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.redFontColor {
  color: red;
}

.blueFontColor {
  color: blue;
}