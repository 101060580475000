@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  width: 650px;
  padding: 0 40px 25px;
}

.infoMessage {
  padding: 30px 0 10px;
  @include typo.font-16-500;
  color: color.$default-font-color;
}

.messageLabel {
  @include typo.font-16-500;
  color: color.$default-font-color;
}

.messageText {
  @include typo.font-16-400;
  color: color.$default-font-color;
}

.infoLabel {
  padding: 10px 0;
  @include typo.font-16-500;
  color: color.$default-font-color;
}

.infoLog {
  padding: 10px 20px;
  white-space: pre-wrap;
  border: 1px solid color.$gray-900;
  max-height: 600px;
  @include typo.font-14-400;
  color: color.$gray-400;
  overflow-y: auto;
  overflow-y: overlay;
}

.footer {
  margin-top: 30px;
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 15px;
  grid-template-columns: 180px;
}
