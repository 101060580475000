@use '/src/style/css/variable/size.scss';
@use '/src/style/css/typo.scss';

.root {
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-width: size.$app-min-width;
  min-height: size.$app-min-height;
  background-color: rgba(white, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  @include typo.font-20-500;
}

.spinner {
  width: 150px;
  height: 150px;
}