@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  width: 540px;
  padding: 0 75px 40px;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: pre;
  @include typo.font-12-700;
  color: color.$default-font-color;
  margin-bottom: 40px;
  gap: 40px;
}

.head {
  @include typo.font-24-700;
  margin-bottom: 20px;
  text-align: center;
  text-wrap: balance;
}

.desc {
  @include typo.font-12-400;
  text-align: center;
  text-wrap: balance;
}

.footer {
  height: 60px;
  display: grid;
}

.submitBtn {
  border-radius: 4px;
  @include typo.font-18-500;
}
