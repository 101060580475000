@use '/src/style/css/variable/color.scss';

.root {
  color: color.$gray-450;
  text-decoration: none;
}

.hover {
  &:hover {
    color: color.$gray-300;
  }
}

.isDisabledClick {
  cursor: default;
}

.isActive {
  color: color.$black-100;
}

.noDisplay {
  display: none;
}
