@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  width: 1240px;
  height: calc(100% - 40px);
  color: color.$black-200;
  padding: 0 80px 80px;
  overflow: auto;
}

.header {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  @include typo.font-20-600;
}

.companyName {
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.downloadButton {
  text-decoration: underline;
  display: flex;
  align-items: center;
  gap: 10px;
  @include typo.font-12-700;
  color: color.$default-font-color;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  .downloadIconWrapper {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: color.$default-black-color;
    .downloadIcon {
      width: 16px;
      height: 16px;
      color: white;
    }
  }
}

.infoLabel {
  @include typo.font-12-700;
  color: color.$default-font-color;
  display: flex;
  align-items: center;
}

.companyInfo {
  display: flex;
  margin-top: 30px;
  gap: 15px;
  margin-bottom: 20px;
}

.defaultInfo {
  width: 300px;
  border: 1px solid color.$gray-800;
  border-radius: 4px;
  padding: 25px;
  background-color: color.$white-500;
}

.defaultTitle {
  @include typo.font-14-700;
}

.defaultDetail {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
}

.detailLabel {
  @include typo.font-12-700;
}

.detailText {
  @include typo.font-12-400;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.adviceArea {
  width: 550px;
  flex: 1 1 0;
  border: 1px solid color.$gray-800;
  border-radius: 4px;
  padding: 25px;
  background-color: color.$white-500;
}

.adviceItem {
  overflow: auto;
}

.adviceHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.adviceTitle {
  @include typo.font-14-600;
}

.loanGrades {
  @include typo.font-12-500;
}

.adviceList {
  @include typo.font-12-400;
  list-style: unset;
  margin: 1em 0;
  padding: 0 0 0 40px;
  margin-top: 20px;
  overflow-y: auto;
  > li {
    margin-bottom: 10px;
  }
}

.line {
  border-top: 1px solid color.$gray-450;
  height: 0px;
}