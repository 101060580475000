@use '/src/style/css/variable/size.scss';

@mixin app-layout {
  min-width: size.$app-min-width;
  min-height: size.$app-min-height;
  width: 100%;
  height: 100%;
}

@mixin lnb-layout {
  min-height: size.$app-min-height;
  width: size.$left-nav-bar-width;
  height: 100%;
}

@mixin rpc-layout {
  width: size.$right-page-width;
  max-width: size.$max-right-page-width;
  height: size.$right-page-height;
  overflow-x: hidden;
  overflow-y: auto;
}

@mixin page {
  width: size.$page-content-width;
  margin-left: auto;
  margin-right: auto;
}

@mixin login-layout {
  height: 100%;
}
