@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  display: inline-block;
  width: 15px;
  height: 15px;
  &:has(input:disabled) {
    opacity: 0.5;
  }
  input {
    opacity: 0;
    &:not(:checked) ~ .emptyBox {
      margin-top: -16.5px;
      width: 15px;
      height: 15px;
      display: block;
      background-color: white;
      border: 1px solid color.$gray-660;
      border-radius: 18px;
    }
    &:checked ~ .checked {
      margin-top: -16.5px;
      width: 15px;
      height: 15px;
      display: block;
      color: color.$secondary-color;
    }
  }
}

.emptyBox, .checked {
  display: none;
}
