@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  width: 100%;
  display: grid;
  grid-template-columns: 5fr 3fr;
  gap: 35px;
  flex: 1 1 0;
}

.segments {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.segmentBtnIcon {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.segmentBtnText {
  @include typo.font-10-500;
}

.list {
  flex: 1 1 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
