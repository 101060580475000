@use '/src/style/css/variable/color.scss';
@use '/src/style/css/typo.scss';

.root {
  color: color.$gray-100;
  text-decoration: none;
  width: 100%;
  height: 60px;
  text-align: center;
  @include typo.font-12-500;
  .icon {
    display: inline-block;
    width: 36px;
    height: 36px;
    margin-bottom: 10px;
  }
}

.hover {
  &:hover {
    color: white;
  }
}

.isDisabledClick {
  cursor: default;
}

.isActive {
  color: white;
}

.noDisplay {
  display: none;
}
