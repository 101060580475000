@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  padding: 10px 0;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  &.selected {
    background-color: color.$blue-300;
  }
}

.smSize {
  @include typo.font-14-400;
  padding: 10px 12px;
}

.mdSize {
  @include typo.font-14-500;
  padding: 10px 20px;
}

.lgSize {
  @include typo.font-14-500;
  padding: 10px 20px;
}
