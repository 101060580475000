@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  @include typo.font-11-500;
  width: 100%;
  height: 45px;
  flex-shrink: 0;
  background-color: white;
  border: 1px solid color.$gray-650;
  color: color.$default-font-color;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 10px;
  cursor: pointer;
  &.active {
    background-color: color.$secondary-color;
    border: 1px solid color.$secondary-color;
    color: white;
  }
}
