@use '/src/style/css/variable/color.scss';
@use '/src/style/css/variable/size.scss';
@use '/src/style/css/typo.scss';

.root {
  position: absolute;
  z-index: 90;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-width: size.$app-min-width;
  min-height: size.$app-min-height;
  background-color: rgba(black, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  &.alignVModalTop {
    align-items: start;
  }
  &.alignVModalCenter {
    align-items: center;
  }
  &.alignVModalBottom {
    align-items: end;
  }
  &.alignHModalLeft {
    justify-content: start;
  }
  &.alignHModalCenter {
    justify-content: center;
  }
  &.alignHModalRight {
    justify-content: end;
  }
}

.modal {
  background-color: white;
  min-width: 300px;
  min-height: 200px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.15);
}

.header {
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  background-color: color.$secondary-color;
  color: white;
  .headerTitle {
    @include typo.font-20-700;
    margin: auto;
  }
}

.nonHeaderTitle {
  height: 50px;
  background-color: white;
  color: color.$black-200;
  justify-content: flex-end;
  padding: 0 17px;
  .closeButton {
    width: 16px;
    height: 16px;
  }
}

.closeButton {
  cursor: pointer;
}
