@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.body {
  background-color: color.$gray-900;
  border-radius: 5px;
  display: grid;
  align-items: center;
  grid-template:
  "filter      fieldConditions" auto
  "fieldList   fieldConditions" 285px / 250px 350px;
}

.filter {
  padding: 10px;
  grid-area: filter;
  .inputWrapper {
    width: 100%;
    display: inline-flex;
    align-items: center;
    border-radius: 6px;
    background-color: white;
    .suffixIcon {
      width: 40px;
      color: color.$black-200;
    }
  }
}

.fieldList {
  height: 100%;
  padding: 10px;
  grid-area: fieldList;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.fieldItem {
  width: 100%;
  height: 45px;
  background-color: white;
  border: 1px solid color.$gray-650;
  color: color.$default-font-color;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  padding: 10px 20px 10px;
  @include typo.font-11-500;
  cursor: pointer;
  &.active {
    background-color: color.$purple-170;
    border: 1px solid color.$purple-120;
  }
  .fieldName {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .selectedIcon {
    color: color.$secondary-color;
    height: 24px;
    width: 24px;
  }
  .notSelectedIcon {
    fill: none;
    height: 24px;
    width: 24px;
    >circle, >path {
      stroke: color.$gray-800;
    }
  }
}

.fieldConditions {
  grid-area: fieldConditions;
  height: 100%;
  border-left: 1px solid color.$gray-660;
  display: grid;
  grid-template-rows: auto 220px auto;
}

.conditionTitle {
  @include typo.font-12-500;
  color: color.$default-font-color;
  padding: 0 15px;
  height: 54px;
  display: flex;
  align-items: center;
}

.noActiveVariable {
  display: flex;
  align-items: center;
  justify-content: center;
  color: color.$gray-400;
}

.conditionFooter {
  border-top: 1px solid color.$gray-660;
  display: flex;
  justify-content: flex-end;
  padding: 15px;
}