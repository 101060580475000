@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.body {
  max-height: 545px;
  overflow-y: auto;
  border-bottom: 1px solid color.$gray-800;
  padding: 40px 30px 30px;
}

.fieldInfo {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
}

.title {
  @include typo.font-14-600;
  color: color.$default-font-color;
  margin-bottom: 20px;
}

.required {
  margin-left: 5px;
  color: color.$primary-color;
}

.separator {
  border-top: 1px solid color.$purple-150;
  margin: 25px 0 15px;
  .legend {
    margin-top: -10px;
    text-align: center;
    label {
      @include typo.font-12-400;
      background-color: white;
      color: color.$secondary-color;
      padding: 0 10px;
    }
  }
}

.optionalCtrl {
  @include typo.font-14-600;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  margin-bottom: 10px;
}

.footer {
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 15px;
  grid-template-columns: 160px 160px;
  padding: 20px 0;
}
