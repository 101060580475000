@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  width: 650px;
  padding: 50px 40px 25px;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  row-gap: 10px;
  align-items: center;
  margin: 0 0 40px;
}

.item {
  display: grid;
  grid-template-columns: 150px auto;
  gap: 5px;
  align-items: center;
  width: 100%;
}

.errorText {
  grid-column: 2;
  height: 15px;
  @include typo.font-10-400;
  color: color.$red-orange;
}

.footer {
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 15px;
  grid-template-columns: 180px 180px;
}
