@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  border-radius: 2px;
  background: color.$default-button-color;
  border: 1px solid color.$default-button-color;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.7;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &.rounded {
    border-radius: 99px;
  }
  &.outlined {
    border: 1px solid color.$default-button-color;
    background-color: white;
    color: color.$default-button-color;
  }
}

.xsSize {
  @include typo.font-12-500;
  border-radius: 6px;
  padding: 0 3px;
  height: 30px;
}

.smSize {
  @include typo.font-12-500;
  border-radius: 6px;
  padding: 0 15px;
  height: 32px;
}

.mdSize {
  @include typo.font-16-400;
  border-radius: 8px;
  height: 44px;
}

.lgSize {
  @include typo.font-18-500;
  border-radius: 8px;
  padding: 0 25px;
  height: 52px;
}

.primary {
  background-color: color.$primary-color;
  border: 1px solid color.$primary-color;
  color: white;
  &.outlined {
    border: 1px solid color.$primary-color;
    background-color: white;
    color: color.$primary-color;
  }
}

.secondary {
  background-color: color.$secondary-color;
  border: 1px solid color.$secondary-color;
  color: white;
  &.outlined {
    border: 1px solid color.$secondary-color;
    background-color: white;
    color: color.$secondary-color;
  }
}

.tertiary {
  background-color: color.$tertiary-color;
  border: 1px solid color.$gray-680;
  color: color.$secondary-color;
  &.outlined {
    border: 1px solid color.$gray-600;
    background-color: white;
    color: color.$gray-600;
  }
}

.orange {
  background-color: color.$orange-100;
  border: 1px solid color.$orange-100;
  color: white;
  &.outlined {
    border: 1px solid color.$orange-100;
    background-color: white;
    color: color.$orange-100;
  }
}

.purple {
  background-color: color.$purple-100;
  border: 1px solid color.$purple-100;
  color: white;
  &.outlined {
    border: 1px solid color.$purple-100;
    background-color: white;
    color: color.$purple-100;
  }
}

.blue {
  background-color: color.$blue-100;
  border: 1px solid color.$blue-100;
  color: white;
  &.outlined {
    border: 1px solid color.$blue-100;
    background-color: white;
    color: color.$blue-100;
  }
}

.pink {
  background-color: color.$pink-100;
  border: 1px solid color.$pink-100;
  color: white;
  &.outlined {
    border: 1px solid color.$pink-100;
    background-color: white;
    color: color.$pink-100;
  }
}

.mint {
  background-color: color.$mint-100;
  border: 1px solid color.$mint-100;
  color: white;
  &.outlined {
    border: 1px solid color.$mint-100;
    background-color: white;
    color: color.$mint-100;
  }
}

.black {
  background-color: color.$black-200;
  border: 1px solid color.$black-200;
  color: white;
  &.outlined {
    border: 1px solid color.$black-200;
    background-color: white;
    color: color.$black-200;
  }
}