@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  border-bottom: 1px solid color.$gray-680;
}

.title {
  @include typo.font-14-700;
  margin-bottom: 20px;
  display: inline-flex;
  gap: 20px;
  align-items: center;
}

.segmentDesc {
  width: 190px;
  overflow-wrap: break-word;
  white-space: break-spaces;
}

.clusterSet {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  gap: 5px;
}

.edit {
  width: 8px;
  height: 8px;
}

.footer {
  margin-bottom: 5px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tags {
  display: inline-flex;
  gap: 30px;
}

.tagItem {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.tagValue {
  @include typo.font-12-500;
  color: color.$gray-300;
}

.actions {
  display: inline-flex;
  gap: 15px;
}

.actionIcon {
  width: 15px;
  height: 15px;
  color: color.$gray-300;
}
