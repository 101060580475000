@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.bell {
  border-radius: 22px;
  width: 32px;
  height: 32px;
  overflow: hidden;
  cursor: pointer;
  color: color.$default-black-color;
}

.anchor {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  right: -75px;
  margin-top: 20px;
  width: 400px;
  min-height: 125px;
  max-height: 425px;
  position: absolute;
  border: 1px solid color.$gray-800;
  border-top: 4px solid color.$secondary-color;
  border-radius: 6px;
  background-color: white;
  color: color.$default-black-color;
  z-index: 1;
  box-shadow: 1px 1px 3px 0px color.$gray-800;
  overflow-y: auto;
  overflow-y: overlay;
}

.item {
  height: 50px;
  @include typo.font-16-500;
  border-bottom: 1px solid color.$gray-800;
  color: color.$gray-400;
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  &:hover {
    background-color: color.$white-500;
  }
}

.successIcon {
  width: 12px;
  height: 12px;
  color: color.$secondary-color;
}

.failIcon {
  width: 12px;
  height: 12px;
  color: color.$primary-color;
}

.text {
  margin-left: 15px;
  @include typo.font-16-500;
}

.noContentItem {
  height: 125px;
  color: color.$gray-400;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid color.$gray-800;
  @include typo.font-16-500;
}