@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  width: 500px;
  padding: 0 40px 25px;
}

.body {
}

.title {
  @include typo.font-14-700;
  color: color.$default-font-color;
}

.lookbackPeriod {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selectCompany {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

.allCompanySelector {
  height: 400px;
}

.selectTitle {
  @include typo.font-14-700;
  color: color.$default-font-color;
  margin-bottom: 20px;
}

.selectSub {
  @include typo.font-12-500;
  color: color.$default-font-color;
  margin-left: 10px;
  margin-bottom: 30px;
}

.processingImg {
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinner {
  width: 150px;
  height: 150px;
}

.downloadArea {
  @include typo.font-12-500;
  color: color.$default-font-color;
  margin-left: 10px;
  margin-bottom: 30px;
}

.downloadLabel {
  display: flex;
  align-items: center;
  gap: 10px;
}

.branchTooltip {
  min-width: 170px;
  max-width: 300px;
  overflow-wrap: break-word;
  white-space: break-spaces;
}

.downloadLink {
  color: color.$secondary-color;
  cursor: pointer;
  margin-left: 10px;
  &:hover {
    text-decoration: underline;
  }
}

.selectArea {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1 0;
}

.selectItem {
  padding: 0 20px;
  height: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.companyName {
  margin-left: 15px;
  @include typo.font-12-400;
  color: color.$default-font-color;
}

.footer {
  margin-top: 50px;
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 15px;
  grid-template-columns: 180px 180px;
}
