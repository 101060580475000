@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  padding: 10px 15px 15px;
  display: grid;
  row-gap: 10px;
  align-content: start;
  grid-template-rows: repeat(auto-fill, 45px)
}

.condition {
  width: 320px;
  display: grid;
  gap: 5px;
  grid-template-columns: 120px 1fr;
}

.opertators {
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  height: 44px;
}

.opertatorItem {
  @include typo.font-14-400;
  color: color.$default-font-color;
  display: flex;
  align-items: center;
  span {
    margin-right: 10px;
  }
}