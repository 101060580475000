@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  display: flex;  
}

.item {
  width: 240px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: color.$gray-900;
  color: color.$gray-450;
  cursor: pointer;
  @include typo.font-16-600;
}

.active {
  background-color: color.$black-100;
  color: white;
}
