@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  display: inline-block;
  position: relative;
}

.control {
  border: 1px solid color.$gray-800;
  color: color.$default-font-color;
  background-color: white;
  display: grid;
  gap: 8px;
  align-items: center;
  grid-template-columns: 1fr 16px;
  cursor: pointer;
  &.active {
    border: 1px solid color.$purple-130;
    box-shadow: 0 0 0 1px color.$purple-130;
  }
  &.disabled {
    opacity: 0.6;
    &.isDarkDisabled {
      background-color: color.$gray-680;
    }
    color: white;
    cursor: default;
  }
  .placeholder {
    color: color.$gray-500;
  }
  .selectedIcon {
    color: color.$secondary-color;
    height: 24px;
    width: 24px;
  }
  .notSelectedIcon {
    fill: none;
    height: 24px;
    width: 24px;
    >circle, >path {
      stroke: color.$gray-800;
    }
  }
  .dropdownIcon {
    color: color.$gray-200;
    &.disabled {
      color: #949494;
    }
    &.reversed {
      transform: rotatex(180deg),
    }
  }
}

.smSize {
  @include typo.font-14-400;
  border-radius: 6px;
  padding: 0 12px 0 0;
  &.selectedVisible {
    grid-template-columns: 1fr 24px 16px;
  }
  &.smWidth {
    width: 42.5px;
  }
  &.mdWidth {
    width: 59.5px;
  }
  &.lgWidth {
    width: 93.5px;
  }
}

.mdSize {
  @include typo.font-14-500;
  border-radius: 6px;
  padding: 0 12px 0 0;
  &.selectedVisible {
    grid-template-columns: 1fr 24px 16px;
  }
  &.smWidth {
    width: 50px;
  }
  &.mdWidth {
    width: 70px;
  }
  &.lgWidth {
    width: 110px;
  }
}

.lgSize {
  @include typo.font-14-500;
  border-radius: 6px;
  padding: 0 12px 0 0;
  &.selectedVisible {
    grid-template-columns: 1fr 24px 16px;
  }
  &.smWidth {
    width: 55px;
  }
  &.mdWidth {
    width: 81px;
  }
  &.lgWidth {
    width: 121px;
  }
}
