@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  // width: 650px;
  padding: 50px 40px 30px;
}

.header {
  margin-bottom: 10px;
  @include typo.font-14-700;
}

.clusterList {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 600px;
}

.footer {
  margin-top: 40px;
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 15px;
  grid-template-columns: 180px 180px;
}
