@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  padding: 0;
  border-radius: 2px;
  background-color: color.$default-button-color;
  border: 1px solid color.$default-button-color;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.7;
  }
  &.rounded {
    border-radius: 99px;
  }
  &.outlined {
    border: 1px solid color.$default-button-color;
    background-color: white;
    color: color.$default-button-color;
  }
  &.noShape {
    border: 0px;
    background-color: initial;
    color: color.$default-button-color;
  }
}


.xsSize {
  @include typo.font-10-400;
  border-radius: 4px;
  width: 16px;
  height: 16px;
}

.smSize {
  @include typo.font-12-500;
  border-radius: 4px;
  width: 20px;
  height: 20px;
}

.mdSize {
  @include typo.font-16-400;
  border-radius: 5px;
  width: 30px;
  height: 30px;
}

.primary {
  background-color: color.$primary-color;
  border: 1px solid color.$primary-color;
  color: white;
  &.outlined {
    border: 1px solid color.$primary-color;
    background-color: white;
    color: color.$primary-color;
  }
  &.noShape {
    border: 1px solid white;
    background-color: white;
    color: color.$primary-color;
  }
}

.secondary {
  background-color: color.$secondary-color;
  border: 1px solid color.$secondary-color;
  color: white;
  &.outlined {
    border: 1px solid color.$secondary-color;
    background-color: white;
    color: color.$secondary-color;
  }
  &.noShape {
    border: 1px solid white;
    background-color: white;
    color: color.$secondary-color;
  }
}

.tertiary {
  background-color: color.$tertiary-color;
  border: 1px solid color.$gray-680;
  color: color.$secondary-color;
  &.outlined {
    border: 1px solid color.$gray-600;
    background-color: white;
    color: color.$gray-600;
  }
  &.noShape {
    border: 1px solid white;
    background-color: white;
    color: color.$gray-600;
  }
}

.orange {
  background-color: color.$orange-100;
  border: 1px solid color.$orange-100;
  color: white;
  &.outlined {
    border: 1px solid color.$orange-100;
    background-color: white;
    color: color.$orange-100;
  }
  &.noShape {
    border: 1px solid white;
    background-color: white;
    color: color.$orange-100;
  }
}

.purple {
  background-color: color.$purple-100;
  border: 1px solid color.$purple-100;
  color: white;
  &.outlined {
    border: 1px solid color.$purple-100;
    background-color: white;
    color: color.$purple-100;
  }
  &.noShape {
    border: 1px solid white;
    background-color: white;
    color: color.$purple-100;
  }
}

.blue {
  background-color: color.$blue-100;
  border: 1px solid color.$blue-100;
  color: white;
  &.outlined {
    border: 1px solid color.$blue-100;
    background-color: white;
    color: color.$blue-100;
  }
  &.noShape {
    border: 1px solid white;
    background-color: white;
    color: color.$blue-100;
  }
}

.balck {
  background-color: color.$black-200;
  border: 1px solid color.$black-200;
  color: white;
  &.outlined {
    border: 1px solid color.$black-200;
    background-color: white;
    color: color.$black-200;
  }
  &.noShape {
    border: 1px solid white;
    background-color: white;
    color: color.$black-200;
  }
}
