@use '/src/style/css/variable/color.scss';
@use '/src/style/css/variable/size.scss';
@use '/src/style/css/typo.scss';

.root {
  position: absolute;
  z-index: 90;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-width: size.$app-min-width;
  min-height: size.$app-min-height;
  background-color: rgba(black, 0);
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: .5s background-color;
}

.leftSide {
  justify-content: start;
}

.rightSide {
  justify-content: end;
}

.transitionBackground {
  background-color: rgba(black, 0.6);
}

.modal {
  background-color: white;
  min-width: 1240px;
  min-height: 100%;
  height: 100%;
  box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
  transition: .5s margin;
}

.leftModal {
  margin-left: -1240px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.rightModal {
  margin-right: -1240px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.transitionModal {
  margin-left: 0px;
  margin-right: 0px;
}

.header {
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  background-color: color.$secondary-color;
  color: white;
  .headerTitle {
    @include typo.font-20-700;
    margin: auto;
  }
}

.nonHeaderTitle {
  height: 50px;
  background-color: white;
  color: color.$black-200;
  justify-content: flex-end;
  padding: 0 17px;
  .closeButton {
    width: 16px;
    height: 16px;
  }
}

.closeButton {
  cursor: pointer;
}
