@use '/src/style/css/typo.scss';
@use '/src/style/css/layout.scss';
@use '/src/style/css/variable/color.scss';

.root {
  @include layout.page;
  padding-top: 40px;
}

.image {
  width: 90px;
  height: 90px;
  border-radius: 45px;
}

.headerText {
  margin-left: 30px;
  color: color.$default-font-color;
  .title {
    @include typo.font-42-700;
    margin: 0 0 5px 0;
  }
  .description {
    @include typo.font-16-400
  }
}

.sectionTitle {
  @include typo.font-28-700;
  color: color.$default-font-color;
  margin-top: 50px;
}

.projectListSectionTitle {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.projectListButton {
  text-decoration: underline;
  display: flex;
  align-items: center;
  gap: 10px;
  @include typo.font-12-700;
  color: color.$default-font-color;
  &:hover {
    opacity: 0.7;
  }
  .projectListIconWrapper {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: color.$default-black-color;
    .projectListIcon {
      width: 16px;
      height: 16px;
      color: white;
    }
  }
}

.cardSection {
  margin-top: 20px;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.projectSection {
  margin-top: 20px;
  height: 225px;
}