@use '/src/style/css/variable/color.scss';
@use '/src/style/css/typo.scss';

.root {
  display: inline-grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  border: 1px solid color.$gray-800;
  color: color.$default-font-color;
  border-radius: 6px;
}

.prefix {
  padding-left: 12px;
  color: color.$gray-450;
}

.suffix {
  padding-right: 12px;
  color: color.$gray-450;
}

.xsSize {
  @include typo.font-12-400;
}

.smSize {
  @include typo.font-14-400;
}

.mdSize {
  @include typo.font-14-500;
}

.lgSize {
  @include typo.font-16-500;
}

.fullWidth {
  width: 100%;
}

.disabled {
  opacity: 0.6;
  &.isDarkDisabled {
    background-color: color.$gray-680;
  }
  color: white;
}