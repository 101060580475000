@use '/src/style/css/layout.scss';
@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  @include layout.page;
  padding-top: 40px;
}

.headerText {
  @include typo.font-42-700;
  color: color.$black-100;
  margin-right: 120px;
}

.dashboardNavItem {
  display: flex;
  gap: 40px;
}

.navText {
  @include typo.font-20-700;

}