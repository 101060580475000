@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  width: 1000px;
  padding: 50px 40px 25px;
}

.body {
}

.header {
  @include typo.font-20-700;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.flexEnd2End {
  justify-content: space-between;
}

.filterBtnContent {
  display: flex;
  gap: 10px;
  width: 100%;
}

.dropdownIcon {
  margin-top: -2px;
  &.reversed {
    transform: rotatex(180deg),
  }
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.transitionCollapse {
  height: 0px;
}

.listGrid {
  margin-top: 15px;
  width: 100%;
  overflow: hidden;
}

.footer {
  margin-top: 50px;
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 15px;
  grid-template-columns: 180px 180px;
}
