@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  position: relative;
  min-height: 30px;
  border-radius: 15px;
  padding: 7px 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: color.$blue-050;
  color: white;
  gap: 3px;
  &.noConditions {
    background-color: color.$gray-600;
  }
  .hoverAction {
    display: none;
  }
  &:hover > .hoverAction {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 25px;
    background-color: rgba(color.$black-100, 0.7);
    display: flex;
    justify-content: flex-end;
  }
}

.deleteAction {
  color: white;
}

.field {
  @include typo.font-12-700;
  margin-right: 12px;
}

.condition {
  @include typo.font-12-400;
}
