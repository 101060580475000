@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  margin-top: 5px;
  position: absolute;
  z-index: 1;
  display: flex;
  gap: 10px;
}

.fields {
  border: 1px solid color.$gray-800;
  background-color: white;
  border-radius: 4px;
  box-shadow: 1px 2px 4px color.$gray-100;
  display: grid;
  align-items: center;
  grid-template:
  "filter" auto
  "fieldList" 280px;
  width: 200px;
}

.filter {
  padding: 10px;
  grid-area: filter;
  .inputWrapper {
    padding: 0 0 0 7px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    border-radius: 6px;
    background-color: white;
    border: 1px solid color.$gray-660;
    .icon {
      width: 15px;
      height: 14px;
      color: color.$black-200;
    }
  }
}

.fieldList {
  height: 100%;
  padding: 10px;
  grid-area: fieldList;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.fieldItem {
  width: 100%;
  height: 45px;
  background-color: white;
  border: 1px solid white;
  color: color.$default-font-color;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  padding: 10px 10px 10px;
  @include typo.font-12-500;
  cursor: pointer;
  &:hover {
    background-color: color.$white-500;
  }
  &.active {
    background-color: color.$purple-170;
    border: 1px solid color.$purple-120;
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
  .fieldName {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .selectedIcon {
    color: color.$secondary-color;
    height: 24px;
    width: 24px;
  }
  .notSelectedIcon {
    fill: none;
    height: 24px;
    width: 24px;
    >circle, >path {
      stroke: color.$gray-800;
    }
  }
}
  