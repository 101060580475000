@use '/src/style/css/variable/color.scss';
@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/size.scss';

.root {
  min-height: size.$app-min-height;
  background-color: color.$purple-190;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
}

.inputArea {
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: color.$default-font-color
}

.inputHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.welcomeLabel {
  @include typo.font-45-500;
}

.inputForm {
  margin-top: 50px;
  width: 390px;
  display: flex;
  flex-direction: column;
}

.inputLabel {
  margin-top: 25px;
  margin-bottom: 5px;
  @include typo.font-12-700;
}

.infoControlArea {
  display: flex;
  justify-content: space-between;
  @include typo.font-12-400;
  margin-top: 20px;
  margin-bottom: 25px;
}

.rememberMeCheckbox {
  display: flex;
  align-items: center;
}

.checkLabel {
  margin-left: 10px;
  color: color.$gray-450;
}

.link {
  @include typo.font-12-600;
  color: color.$sky-purple;
  cursor: pointer;
}

.createArea {
  display: flex;
  justify-content: center;
  gap: 15px;
  @include typo.font-12-400;
  margin-top: 35px;
}

.inputFooter {
  position: absolute;
  bottom: 0;
  color: color.$black-300;
  @include typo.font-12-400;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
}

.imageArea {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lottieSvg {
  margin-left: -10%;
  margin-right: 8%;
}