@use '/src/style/css/typo.scss';
@use '/src/style/css/layout.scss';
@use '/src/style/css/variable/color.scss';


.root {
  width: 100%;
  border: 1px solid color.$gray-800;
  border-radius: 10px;
  overflow: hidden;
}

.item {
  display: grid;
  height: 125px;
  align-items: center;
  padding: 0 50px;
  box-shadow: 0 -1px color.$gray-800;
  grid-template-columns: 380px auto 130px;
}

.title {
  @include typo.font-16-400;
}

.userValue {
  @include typo.font-16-600;
}

.profile {
  border-radius: 40px;
  width: 60px;
  height: 60px;
  overflow: hidden;
  background-color: color.$gray-200;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: white;
  @include typo.font-24-500;
  img {
    width: 100%;
    height: 100%;
  }
}

.passwordTextArea {
  display: flex;
  gap: 6px;
}

.passwordItem {
  background-color: color.$default-font-color;
  border-radius: 5px;
  width: 8px;
  height: 8px;
}

.buttonText {
  @include typo.font-16-400;
}

.selector {
  width: 400px;
  display: flex;
  flex-direction: column;
}
