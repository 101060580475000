@use '/src/style/css/typo.scss';
@use '/src/style/css/layout.scss';
@use '/src/style/css/variable/color.scss';


.root {
  width: 100%;
  border-radius: 10px;
  background-color: color.$blue-400;
  padding: 40px 70px 30px;
  color: color.$default-font-color;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.headerTitle {
  @include typo.font-20-700;
}

.buttonWrapper {
  display: inherit;
  gap: 35px;
}

.actionButton {
  text-decoration: underline;
  display: flex;
  align-items: center;
  gap: 10px;
  @include typo.font-16-600;
  color: color.$default-font-color;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  .buttonLabel {
    margin-top: 1px;
  }
}

.addIcon {
  width: 16px;
  height: 16px;
}

.bodyArea {
  border: 1px solid color.$gray-500;
  border-radius: 4px;
  overflow: hidden;
}

.listHeader {
  background-color: color.$black-300;
  height: 48px;
  display: grid;
  grid-template-columns: 75px 150px 1fr;
  align-items: center;
  color: white;
  @include typo.font-14-500;
}

.checkboxCell {
  justify-self: center;
}

.list {
  overflow: auto;
  height: 440px;
  background-color: white;
}

.listItem {
  height: 48px;
  display: grid;
  grid-template-columns: 75px 150px 1fr;
  align-items: center;
  @include typo.font-14-500;
  &:hover {
    background-color: color.$purple-200;
  }
}

.cellItem {
  justify-self: center;
}

.fileArea {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.dragInfoText {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.downloadButton {
  margin-top: 30px;
  text-decoration: underline;
  display: flex;
  align-items: center;
  gap: 10px;
  @include typo.font-12-700;
  color: color.$gray-100;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}

.info {
  @include typo.font-12-500;
  color: color.$secondary-color;
}

.fileSelectButton {
  @include typo.font-14-500;
  height: 36px;
  border-radius: 6px;
  padding: 0 17px;
  background-color: color.$secondary-color;
  border: 1px solid color.$secondary-color;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  input {
    display: none;
  }
  &:hover {
    opacity: 0.7;
  }
}

.onDrag {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 12px;
  border: 2px dashed color.$gray-100;
  background-color: color.$white-500;
  opacity: 0.5;
}

.visible {
  display: block;
}