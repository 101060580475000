@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  background-color: color.$gray-900;
  padding: 10px;
  border-radius: 5px;
  display: grid;
  align-items: center;
  grid-template-rows: 1fr auto;
  gap: 15px;
}

.filter {
  display: inline-flex;
  align-items: center;
  border-radius: 6px;
  background-color: white;
  overflow: hidden;
  .suffixIcon {
    width: 40px;
    color: color.$black-200;
  }
}

.datasetList {
  height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
