@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  width: 100%;
  color: color.$black-200;
}

.tabWrapper {
  @include typo.font-20-600;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: end;
  border-bottom: 1px solid color.$gray-800;
  margin-bottom: 20px;
  height: 60px;
}

.tabContentHeader {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @include typo.font-20-600;
}

.tabContentHeaderController {
  @include typo.font-14-500;
  display: grid;
  align-items: center;
  align-self: center;
  gap: 10px;
  grid-template-columns: 50px 180px;
}

.gradeWrapper {
  display: grid;
  grid-template-columns: .3fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 8px;
}

.grades {
  @include typo.font-14-500;
}

.gradeOrderHeader {
  border-radius: 5px;
  color: white;
  background-color: color.$gray-500;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.gradeHeader {
  border-radius: 5px;
  padding: 6px 0 5px;
  color: white;
  background-color: color.$gray-500;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  align-items: center;
  height: 60px;
  > div:first-child {
    @include typo.font-14-500;
    grid-column: 1 / -1;
  }
  > div:not(:first-child) {
    @include typo.font-12-500;
  }
}

.grade1Color {
  background-color: color.$purple-140;
}

.grade2Color {
  background-color: color.$pink-150;
}

.grade3Color {
  background-color: color.$skyblue-500;
}

.grade4Color {
  background-color: color.$orange-150;
}

.grade5Color {
  background-color: color.$green-400;
}

.fixedColumnColor {
  color: color.$gray-400;
}

.gradeOrderRow {
  margin-top: 5px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.gradeRow {
  margin-top: 5px;
  height: 35px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.gradeRowEven {
  background-color: color.$gray-900;
}

.gradeRowOdd {
  background-color: color.$white-500;
}

.gradeDescription {
  margin-top: 20px;
  padding: 0 20px;
  @include typo.font-15-400;
  color: color.$black-300;
}

.tableHeader {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.downloadButton {
  text-decoration: underline;
  display: flex;
  align-items: center;
  gap: 10px;
  @include typo.font-12-700;
  color: color.$default-font-color;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  .downloadIconWrapper {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: color.$default-black-color;
    .downloadIcon {
      width: 16px;
      height: 16px;
      color: white;
    }
  }
}

.tableWrapper {
  border-radius: 4px;
  border: 1px solid color.$gray-900;
  margin-bottom: 60px;
}

.companyLink {
  color: color.$secondary-color;
  text-decoration: underline;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  cursor: pointer;
}