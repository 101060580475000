@use '/src/style/css/variable/color.scss';

.root {
  display: inline-block;
  width: 18px;
  height: 18px;
  input {
    opacity: 0;
    &:not(:checked) ~ .emptyBox {
      margin-top: -18px;
      width: 18px;
      height: 18px;
      display: block;
      background-color: white;
      border: 1px solid color.$gray-500;
      border-radius: 4px;
    }
    &:checked ~ .checked {
      margin-top: -18px;
      width: 18px;
      height: 18px;
      display: block;
      color: color.$secondary-color;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
}

.emptyBox, .checked {
  display: none;
}