@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  display: inline-grid;
  grid-template-columns: 1fr 30px;
  gap: 5px;
  border: 1px solid color.$gray-800;
  color: color.$default-font-color;
  border-radius: 6px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: color.$gray-500
  }
  &:disabled {
    opacity: 0.6;
    &.isDarkDisabled {
      background-color: color.$gray-680;
    }
    color: white;
  }
  &[type='number'] {
    text-align: right;
  }
  &.isError {
    border: 1px solid color.$red-orange;
  }
}

.xsSize {
  @include typo.font-12-400;
}

.smSize {
  @include typo.font-14-500;
}

.mdSize {
  @include typo.font-14-500;
}

.lgSize {
  @include typo.font-16-500;
}

.borderBottom {
  border-radius: 0px;
  border: 0px solid color.$gray-800;
  border-bottom: 1px solid color.$gray-800;
  &.isError {
    border-bottom: 1px solid color.$red-orange;
  }
}

.borderNone {
  border: 0px solid color.$gray-800;
}

.fullWidth {
  width: 100%;
}

.showIconArea {
  width: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
