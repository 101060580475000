@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  margin-top: 5px;
  border: 1px solid color.$gray-800;
  color: color.$default-font-color;
  position: absolute;
  background-color: white;
  width: 100%;
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
}

.isOpenUpside {
  bottom: 50px;
}

.active {
  border: 1px solid color.$purple-130;
  box-shadow: 0 0 0 1px color.$purple-130;
}

.option {
  padding: 10px 0;
  &:hover {
    opacity: 0.7;
  }
}

.label {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.smSize {
  @include typo.font-14-400;
  border-radius: 6px;
  padding: 8px 0;
  .option {
    padding: 10px 12px;
  }
}

.mdSize {
  @include typo.font-14-500;
  border-radius: 6px;
  padding: 12px 0;
  .option {
    padding: 10px 12px;
  }
}

.lgSize {
  @include typo.font-14-500;
  border-radius: 6px;
  padding: 12px 0;
  .option {
    padding: 10px 12px;
  }
}