@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  width: 650px;
  padding: 50px 40px 25px;
}

.body {
  display: grid;
  grid-template-columns: 150px auto;
  gap: 10px;
  row-gap: 30px;
  align-items: center;
  margin: 0 0 40px;
  .label {
    @include typo.font-16-700;
  }
}

.footer {
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 15px;
  grid-template-columns: 180px 180px;
}
